// TODO: unused, remove?
// eslint-disable-next-line no-redeclare,no-unused-vars
function contextSubtab(elements, param) {
  contextMenu(
    elements,
    {
      rename: 'Tab umbenennen',
      add: 'neuer Tab',
      remove: 'Tab löschen',
    },
    function (type, target) {
      if (type == 'rename') {
        var title = prompt('Name', target.text());
        if (!title) return;
        target.text(title);
        param.view.formSave();
      } else if (type == 'add') {
        title = prompt('Name');
        if (!title) return;
        var key = 'tab' + rand();
        var btn = target
          .clone(true)
          .text(title)
          .removeClass('active')
          .insertAfter(target);
        btn.attr('key', key);
        const index = target.prevAll('.button').length;
        var after = target.parent().nextAll('[data-site=right]').eq(index);
        $(
          '<div class="left hidden"></div><div class="right hidden"></div>'
        ).insertAfter(after);
        param.view.formSortable();
        param.view.formSave();
        contextSubtab(btn, param);
      } else if (type == 'remove') {
        if (target.siblings('.button').length) {
          const index = target.prevAll('.button').length;
          target.parent().nextAll('[data-site=left]').eq(index).remove();
          target.parent().nextAll('[data-site=right]').eq(index).remove();
          target.remove();
        } else target.parent().remove();
        param.view.formSave();
      }
    }
  );
}
